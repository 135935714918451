<template>
  <div>
    <div class="flex md:flex-row gap-2.5">
      <div class="bg-gray-100 p-3 font-medium text-base rounded-md flex items-center w-full" v-if="isShowcaseMode">
        <InformationCircleIcon class="h-4 w-4 mr-2.5"/>
        {{ showcaseDescription }}
      </div>
      <template v-else>
        <div class="mb-2" v-if="quantity !== 0">
          <!-- KG/Gram Seçimi -->
          <template v-if="selectedVariant?.unitType === 2">
            <FormSelect class="min-w-[6rem]" v-model="cartQuantity" :options="weights" input-label="weight" input-value="weight" :placeholder="$t('app.weight')">
              <template #label="{ label }">
                {{label}} /gr
              </template>
              <template #default="{ item, selected }">
                <div class="flex items-center relative justify-between text-gray-500">
                  <span>{{ item.weight }}</span>
                  <span class="">/ gr.</span>
                </div>
                <div class="flex gap-0.5">
                  <div :class="[getDiscountedPrice(item.price) ? 'text-xs opacity-40 line-through' : 'font-bold']">{{ item.price }} TL</div>
                  <div class="font-bold" v-if="getDiscountedPrice(item.price)">{{ getDiscountedPrice(item.price) }} TL</div>
                </div>
              </template>
            </FormSelect>
          </template>

          <FormInputCounter v-model="cartQuantity" :min="1" :max="maxQtyForProduct(product)" :step="quantityStep" v-else/>
        </div>
        <div class="w-full mb-2 md:ml-2 hidden sm:block">
          <div v-if="quantity <= 0" class="btn-sold">
            <StockOutText />
          </div>
          <FormButton
              v-if="quantity > 0"
              color="primary"
              type="button"
              class="uppercase w-full"
              :loading="addCartLoading"
              @click="addToBasket(selectedVariant.variantId, cartQuantity, selectedVariant?.primaryStockData.rawCurrency, product.selectedVariant?.primaryStockData.warehouseId, null, true, false, finalPrice)"
          >
            <LucideShoppingCart class="h-4 w-4 mr-2"/>
            {{ $t('app.add-to-cart') }}
          </FormButton>
        </div>
        <!-- Mobile buttons -->
        <div class="w-full sm:hidden fixed sm:top-auto sm:right-auto bottom-0 left-0 sm:col-span-1 z-50">
          <div class="py-2.5 px-4 bg-white/30 backdrop-blur-md border border-gray-200 flex justify-between">
            <div
                v-if="quantity <= 0"
                class="btn-sold"
            >
              <StockOutText />
            </div>
            <FormButton
                v-if="quantity > 0"
                color="primary"
                type="button"
                class="uppercase w-full"
                :loading="addCartLoading"
                @click="addToBasket(selectedVariant.variantId, cartQuantity, selectedVariant?.primaryStockData.rawCurrency, product.selectedVariant?.primaryStockData.warehouseId, null, true, false, finalPrice)"
            >
              <LucideShoppingCart class="h-4 w-4 mr-2"/>
              {{ $t('app.add-to-cart') }}
            </FormButton>
          </div>
        </div>
      </template>
    </div>
    <AppWidgetBuyViaWhatsApp/>

    <!-- Favorites Button -->
    <div v-if="status === 'authenticated'">
      <FormButton
          class="!font-medium"
          type="button"
          @click="addWishlist(selectedVariant)"
          :aria-label="$t('listing.add-to-wishlist')"
      >
        <template v-if="selectedVariant?.isFavorited" >
          <HeartFilledIcon class="text-red w-5 h-5 mr-2"/> {{ $t('listing.remove-from-wishlist') }}
        </template>
        <template v-if="!selectedVariant?.isFavorited">
          <HeartIcon class="text-primary w-5 h-5 mr-2"/> {{ $t('listing.add-to-wishlist') }}
        </template>
      </FormButton>
    </div>
    <div v-if="status === 'unauthenticated'">
      <FormButton
          class="!font-medium"
          type="button"
          @click="authModalIsVisible = true"
          :aria-label="$t('listing.add-to-wishlist')"
      >
        <HeartIcon class="text-primary w-5 h-5 mr-2"/> {{ $t('listing.add-to-wishlist') }}
      </FormButton>
    </div>
  </div>

</template>

<script setup>
import {HeartIcon, ShoppingBagIcon, InformationCircleIcon} from "@heroicons/vue/24/outline";
import {HeartIcon as HeartFilledIcon} from "@heroicons/vue/24/solid";
import StockOutText from "~/components/StockOutText.vue";
import collect from "collect.js";

const {
  product,
  selectedVariant,
  cartQuantity,
  isShowcaseMode,
  showcaseDescription,
  weights,
  finalPrice
} = await useProduct()

const {
  addCartLoading,
  addToBasket
} = await useBasket()

const {
  addWishlist
} = useWishlist()

const {maxQtyForProduct} = useListing()

const {status} = await useAuth();
const {isWholesaleUser, authModalIsVisible} = await useCustomer();

const quantity = computed(() => {
  return parseInt(selectedVariant.value?.primaryStockData?.quantity)
})

const quantityStep = computed(() => {
  return selectedVariant.value?.primaryStockData?.quantityStep
})

const discountConfig = computed(() => selectedVariant.value?.primaryStockData?.discount?.config)
const getDiscountedPrice = (price) => {
  if (discountConfig.value?.type === 'percent') {
    return price - ((price / 100) * discountConfig.value?.value);
  } else if (discountConfig.value?.type === 'value') {
    return price - discountConfig.value?.value;
  } else {
    return null
  }
}

watch(cartQuantity, (newQuantity) => {
  if (selectedVariant.value?.unitType === 2) {
    const newPrice = collect(weights.value).where('weight', newQuantity).pluck('price').first();
    const discountPrice = getDiscountedPrice(newPrice);
    selectedVariant.value = {
      ...selectedVariant.value,
      primaryStockData : {
        ...selectedVariant.value.primaryStockData,
        price : newPrice,
        discountPrice : discountPrice
      }
    }
  }
})

</script>

<style scoped>

</style>